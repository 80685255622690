import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stage, Rect, Layer, Image as KonvaImage, Transformer, Text } from "react-konva";
import WebFont from "webfontloader";
import { useDrop } from "react-dnd";
import "../NochillioEditor.css";

// Constants
const maxCanvasWidth = 600;
const maxCanvasHeight = 600;
const imageCache = {};

// Hooks
const useImage = (src) => {
    const [image, setImage] = useState(imageCache[src] || null);

    useEffect(() => {
        if (imageCache[src]) {
            setImage(imageCache[src]);
            return;
        }

        const img = new Image();
        img.onload = () => {
            imageCache[src] = img;
            setImage(img);
        };
        img.onerror = (err) => console.error("Failed to load image:", err);
        img.src = src;
    }, [src]);

    return image;
};

// Data
const assetImages = [
    { url: "./images/assets/nochillio_logo.png", name: "nochillio logo" },
    { url: "./images/assets/worldorder.png", name: "nochillio world order" },
    { url: "./images/assets/pythhead.png", name: "pyth" },
    { url: "./images/assets/chopperhead.png", name: "chopper" },
    { url: "./images/assets/nochillio face.png", name: "nochillio face" },
    { url: "./images/assets/nochillio face 2.png", name: "nochillio face" },
    { url: "./images/assets/eyes.png", name: "nochillio eyes blue" },
    { url: "./images/assets/eyes2.png", name: "nochillio eyes brown" },
    { url: "./images/assets/NO CHILL LOGO REWORK VECTOR-02.png", name: "nochill" },
    { url: "./images/assets/NO CHILL LOGO REWORK VECTOR-01.png", name: "nochill" },
    { url: "./images/assets/NO CHILL LOGO REWORK VECTOR-03.png", name: "nochill" },
    { url: "./images/assets/PFP-1.png", name: "Arena" },
    { url: "./images/assets/PFP - 2.png", name: "Arena" },
    { url: "./images/assets/Arena_Logo.png", name: "Arena" },
    { url: "./images/assets/Arena_LockUp_alt.png", name: "Arena" },
    { url: "./images/assets/Arena_LockUp.png", name: "Arena" },
    { url: "./images/assets/Arena_Wordmark_left.png", name: "Arena" },
    { url: "./images/assets/Arena_Wordmark_Perspective.png", name: "Arena" },
];

const templateTemplates = [
    { url: "./images/filters/wider.jpg", name: "drake-chillio" },
    { url: "./images/filters/trade_offer.jpg", name: "trade-offer-chillio" },
    { url: "./images/filters/change_my_mind.jpg", name: "change-my-mind-chillio" },
    { url: "./images/filters/wide_sass.jpg", name: "sass-chillio" },
    { url: "./images/filters/yall_got_anymore.jpg", name: "chapelle-chillio" },
];

const overlayTemplates = [
    { url: "./images/filters/bloodfilter.png", name: "blood overlay" },
    { url: "./images/filters/fire_overlay.png", name: "fire overlay" },
    { url: "./images/filters/hearts_overlay.png", name: "hearts overlay" },
];

// Components
const Modal = ({ isOpen, close, children, className }) => {
    if (!isOpen) return null;
    return (
        <div className={`modal-overlay ${className}`} onClick={close}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

const TextModal = ({
    isOpen,
    close,
    addTextToCanvas,
    fontStyle,
    setFontStyle,
    textOption,
    setTextOption,
}) => (
    <Modal isOpen={isOpen} close={close} className="text-modal">
        <div className="text-modal-content">
            <h3>Text Location</h3>
            <button onClick={() => setTextOption("top")}>{textOption === "top" ? "✔️ Top" : "Top"}</button>
            <button onClick={() => setTextOption("bottom")}>{textOption === "bottom" ? "✔️ Bottom" : "Bottom"}</button>
            <button onClick={() => setTextOption("free")}>{textOption === "free" ? "✔️ Floating" : "Floating"}</button>
            {textOption && (
                <>
                    <h3>Text Style</h3>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <label>
                                <input type="checkbox" checked={fontStyle.bold} onChange={() => setFontStyle({ ...fontStyle, bold: !fontStyle.bold })} />
                                Bold
                            </label>
                            <label>
                                <input type="checkbox" checked={fontStyle.italic} onChange={() => setFontStyle({ ...fontStyle, italic: !fontStyle.italic })} />
                                Italic
                            </label>
                            <label>
                                <input type="checkbox" checked={fontStyle.underline} onChange={() => setFontStyle({ ...fontStyle, underline: !fontStyle.underline })} />
                                Underline
                            </label>
                            <label>
                                <input type="checkbox" checked={fontStyle.lineThrough} onChange={() => setFontStyle({ ...fontStyle, lineThrough: !fontStyle.lineThrough })} />
                                Line Through
                            </label>
                        </div>
                        <div>
                            <label>
                                <input type="radio" checked={fontStyle.color === "black"} onChange={() => setFontStyle({ ...fontStyle, color: "black" })} />
                                Black
                            </label>
                            <label>
                                <input type="radio" checked={fontStyle.color === "white"} onChange={() => setFontStyle({ ...fontStyle, color: "white" })} />
                                White
                            </label>
                        </div>
                    </div>
                    <div className="add-text-button">
                        <button onClick={addTextToCanvas}>Add Text</button>
                    </div>
                </>
            )}
        </div>
    </Modal>
);
const TextStylingModal = ({ isOpen, close, toggleTextColor, toggleTextFormat, selectedTextElement }) => {
    if (!selectedTextElement) return null; // Handle the case where no text element is selected

    return (
        <Modal isOpen={isOpen} close={close} className="text-styling-modal">
            <div className="text-styling-modal-content">
                <h3>Text Styling</h3>
                <div style={{ borderRadius: '5px', textAlign: 'center' }}>
                    <p style={{
                        fontFamily: 'Anton, sans-serif',
                        fontSize: selectedTextElement.fontSize,
                        color: selectedTextElement.color,
                        fontWeight: selectedTextElement.bold ? 'bold' : 'normal',
                        fontStyle: selectedTextElement.italic ? 'italic' : 'normal',
                        textDecoration: `${selectedTextElement.underline ? 'underline ' : ''}${selectedTextElement.lineThrough ? 'line-through' : ''}`,
                        margin: 0,
                        padding: '10px',
                        textShadow: `0px 0px 2px ${selectedTextElement.color === 'black' ? 'white' : 'black'}, 0px 0px 2px ${selectedTextElement.color === 'black' ? 'white' : 'black'}`
                    }}>
                        {selectedTextElement.text}
                    </p>
                </div>
                <button onClick={() => toggleTextColor("black")}>
                    {selectedTextElement.color === "black" ? "✔️ Black" : "Black"}
                </button>
                <button onClick={() => toggleTextColor("white")}>
                    {selectedTextElement.color === "white" ? "✔️ White" : "White"}
                </button>
                <button onClick={() => toggleTextFormat("bold")}>
                    {selectedTextElement.bold ? "✔️ Bold" : "Bold"}
                </button>
                <button onClick={() => toggleTextFormat("italic")}>
                    {selectedTextElement.italic ? "✔️ Italic" : "Italic"}
                </button>
                <button onClick={() => toggleTextFormat("underline")}>
                    {selectedTextElement.underline ? "✔️ Underline" : "Underline"}
                </button>
                <button onClick={() => toggleTextFormat("lineThrough")}>
                    {selectedTextElement.lineThrough ? "✔️ Line Through" : "Line Through"}
                </button>
            </div>
        </Modal>
    );
};

const NochillioEditor = () => {
    // State management
    const [images, setImages] = useState({});
    const [backgroundSrc] = useState(null);
    const backgroundImage = useImage(backgroundSrc);
    const [elements, setElements] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [isInfoModalOpen, setisInfoModalOpen] = useState(true);
    const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const [isNochillioModalOpen, setIsNochillioModalOpen] = useState(false);
    const [isOverlayModalOpen, setIsOverlayModalOpen] = useState(false);
    const [nochillioImage, setNochillioImage] = useState(null);
    const [textElements, setTextElements] = useState([]);
    const [originalFileName, setOriginalFileName] = useState("");
    const [showTransformer, setShowTransformer] = useState(true);
    const [editingState, setEditingState] = useState({ visible: false, x: 0, y: 0, value: "", id: null });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024 && window.innerHeight <= 769);
    const [showAdditionalButtons, setShowAdditionalButtons] = useState(!isMobile);
    const [canvasSize, setCanvasSize] = useState({ width: 600, height: 600 });
    const [canvasTextStyle, setCanvasTextStyle] = useState({
        fontSize: "25px",
        color: "#6eb6c8",
        textAlign: "center",
        marginBottom: "10px",
        paddingLeft: "150px",
    });
    const [assetSize, setAssetSize] = useState({ width: 150, height: 75 });
    const [templateSize, setTemplateSize] = useState({ width: 150, height: 75 });
    const [overLaySize, setOverlaySize] = useState({ width: 150, height: 75 });
    const mobileCanvasSize = Math.min(window.innerWidth, window.innerHeight) * 0.95;
    const [searchTerms, setSearchTerms] = useState({ asset: "", template: "" });
    const [backgroundColor, setBackgroundColor] = useState("black");
    const colorInputRef = useRef(null);
    const handleOpenColorPicker = () => colorInputRef.current.click();
    const handleScenarioChange = (scenario) => setScenario(scenario);
    const handleColorChange = (event) => setBackgroundColor(event.target.value);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [nochillioSearchTerm, setNochillioSearchTerm] = useState("");
    const [scenario, setScenario] = useState(2);
    const [isTextModalOpen, setIsTextModalOpen] = useState(false);
    const [isTextStylingModalOpen, setIsTextStylingModalOpen] = useState(false);
    const [isAddTextSelected, setIsAddTextSelected] = useState(false);
    const [textOption, setTextOption] = useState(null);
    const [textInput, setTextInput] = useState("");
    const [fontStyle, setFontStyle] = useState({
        bold: false,
        italic: false,
        underline: false,
        lineThrough: false,
        color: 'white',
    });
    const stageRef = useRef(null);

    // Effects
	useEffect(() => {
		// Update the fontStyle whenever the selectedId changes
		if (selectedId) {
			const selectedTextElement = textElements.find((textElement) => textElement.id === selectedId);
			if (selectedTextElement) {
				setFontStyle({
					bold: !!selectedTextElement.bold,
					italic: !!selectedTextElement.italic,
					underline: !!selectedTextElement.underline,
					lineThrough: !!selectedTextElement.lineThrough,
					color: selectedTextElement.color || 'black',
				});
			}
		}
	}, [selectedId, textElements]);

    useEffect(() => {
        const allImages = [...assetImages, ...templateTemplates, ...overlayTemplates];
        const loadedImages = {};
        allImages.forEach((image) => {
            const img = new Image();
            img.onload = () => {
                loadedImages[image.url] = img;
                setImages((current) => ({ ...current, [image.url]: img }));
            };
            img.src = image.url;
        });
    }, []);

    useEffect(() => {
        WebFont.load({
            google: { families: ["Anton"] },
            active: () => setElements((prevElements) => [...prevElements]),
        });
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.key === "Backspace" || event.key === "Delete") && selectedId) {
                if (document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA") {
                    event.preventDefault();
                    deleteSelectedImage();
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [selectedId]);

    useEffect(() => {
        const adjustCanvasMobile = () => {
            if (window.innerWidth <= 1024 || window.innerHeight <= 769) {
                setIsMobile(true);
                setCanvasTextStyle({
                    fontSize: "20px",
                    color: "#6eb6c8",
                    textAlign: "center",
                    marginBottom: "10px",
                    paddingLeft: "0px",
                });
                setAssetSize({ width: 70, height: 70 });
                setTemplateSize({ width: 70, height: 70 });
                setOverlaySize({ width: 70, height: 70 });
                setCanvasSize({ width: mobileCanvasSize, height: mobileCanvasSize });
            } else {
                setIsMobile(false);
                setCanvasTextStyle({
                    fontSize: "25px",
                    color: "#6eb6c8",
                    textAlign: "center",
                    marginBottom: "10px",
                    paddingLeft: "150px",
                });
                setAssetSize({ width: 150, height: 150 });
                setTemplateSize({ width: 150, height: 150 });
                setOverlaySize({ width: 150, height: 150 });
                setCanvasSize({ width: 600, height: 600 });
            }
        };
        adjustCanvasMobile();
        window.addEventListener("resize", adjustCanvasMobile);

        return () => window.removeEventListener("resize", adjustCanvasMobile);
    }, [mobileCanvasSize]);

    // Handlers
    const handleSearchChange = (e) => {
        const value = e.target.value;
        if (isNochillioModalOpen) {
            fetchNochillioImages(value);
        } else {
            setSearchTerms({
                ...searchTerms,
                [isAssetModalOpen ? "asset" : "template"]: value,
            });
        }
    };

    const fetchNochillioImages = async (query) => {
        setLoading(true);
        setError(null);

        const encodedQuery = encodeURIComponent(query);
        const urls = [
            `https://nochill.io/nft/images/${encodedQuery}.png`,
            `https://nochill.io/nft/images_nobg/${encodedQuery}.png`,
			`https://nochill.io/nft/images_heads/${encodedQuery}.png`,
        ];

        try {
            const responses = await Promise.all(urls.map((url) => fetch(url)));
            const blobs = await Promise.all(
                responses.map((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.blob();
                })
            );
            const imageObjectURLs = blobs.map((blob) => URL.createObjectURL(blob));
            setNochillioImage(imageObjectURLs);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching images:", err);
            setError("Failed to fetch images");
            setLoading(false);
            setNochillioImage(null);
        }
    };

    const openAssetModal = () => {
        setIsAssetModalOpen(!isAssetModalOpen);
        if (isAssetModalOpen === false) {
            setIsTemplateModalOpen(false);
            setIsNochillioModalOpen(false);
            setIsOverlayModalOpen(false);
        }
    };

    const openTemplateModal = () => {
        setIsTemplateModalOpen(!isTemplateModalOpen);
        if (isTemplateModalOpen === false) {
            setIsAssetModalOpen(false);
            setIsNochillioModalOpen(false);
            setIsOverlayModalOpen(false);
        }
    };

    const openOverlayModal = () => {
        setIsOverlayModalOpen(!isOverlayModalOpen);
        if (isOverlayModalOpen === false) {
            setIsAssetModalOpen(false);
            setIsTemplateModalOpen(false);
            setIsNochillioModalOpen(false);
        }
    };

    const openNochillioModal = () => {
        setIsNochillioModalOpen(!isNochillioModalOpen);
        if (isNochillioModalOpen === false) {
            setIsAssetModalOpen(false);
            setIsTemplateModalOpen(false);
            setIsOverlayModalOpen(false);
        }
    };

    const toggleAdditionalButtons = () => {
        setShowAdditionalButtons(!showAdditionalButtons);
    };

    const filteredAssetImages = searchTerms.asset
        ? assetImages.filter((image) =>
              image.name.toLowerCase().includes(searchTerms.asset.toLowerCase())
          )
        : assetImages;

    const filteredTemplateTemplates = searchTerms.template
        ? templateTemplates.filter((image) =>
              image.name.toLowerCase().includes(searchTerms.template.toLowerCase())
          )
        : templateTemplates;

    const renderAssetModalContent = () => {
        return filteredAssetImages.map((image, index) => {
            const imgElement = images[image.url];
            return (
                <div key={`asset-${index}`} style={{ textAlign: "center", margin: "5px" }}>
                    <button
                        onClick={() =>
                            addElementToCanvas(image.url, assetSize.width, assetSize.height, "asset")
                        }
                    >
                        {imgElement ? (
                            <img
                                src={imgElement.src}
                                alt={image.name}
                                style={{
                                    maxWidth: `${assetSize.width}px`,
                                    maxHeight: `${assetSize.height}px`,
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            <p>Loading...</p>
                        )}
                    </button>
                    <div style={{ marginTop: "7px" }}>{image.name}</div>
                </div>
            );
        });
    };

    const renderTemplateModalContent = () => {
        return filteredTemplateTemplates.map((image, index) => {
            const imgElement = images[image.url];
            return (
                <div key={`template-${index}`} style={{ textAlign: "center", margin: "5px" }}>
                    <button onClick={() => addElementToCanvas(image.url, 0, 0, "template")}>
                        {imgElement ? (
                            <img
                                src={imgElement.src}
                                alt=""
                                style={{
                                    maxWidth: `${templateSize.width}px`,
                                    maxHeight: `${templateSize.height}px`,
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            <p>Loading...</p>
                        )}
                    </button>
                </div>
            );
        });
    };

    const renderOverlayModalContent = () => {
        return overlayTemplates.map((image, index) => {
            const imgElement = images[image.url];
            return (
                <div key={`overlay-${index}`} style={{ textAlign: "center", margin: "5px" }}>
                    <button onClick={() => addElementToCanvas(image.url, 0, 0, "overlay")}>
                        {imgElement ? (
                            <img
                                src={imgElement.src}
                                alt={image.name}
                                style={{
                                    maxWidth: `${overLaySize.width}px`,
                                    maxHeight: `${overLaySize.height}px`,
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            <p>Loading...</p>
                        )}
                    </button>
                </div>
            );
        });
    };

    const renderNochillioModalContent = () => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>{error}</p>;
        return nochillioImage ? (
            nochillioImage.map((imageURL, index) => (
                <button key={index} onClick={() => addElementToCanvas(imageURL, 0, 0, "nochillio", `Nochillio Image ${index + 1}`)}>
                    <img
                        src={imageURL}
                        alt={`Nochillio Image ${index + 1}`}
                        style={{
                            maxWidth: "98%",
                            maxHeight: "98%",
                            borderRadius: "15px",
                            border: "3px solid #000000",
                            marginTop: "2px",
                        }}
                    />
                </button>
            ))
        ) : (
            <p>No images loaded</p>
        );
    };

    const openTextStylingModal = () => {
        if (selectedId) {
            const selectedTextElement = textElements.find((textElement) => textElement.id === selectedId);
            if (selectedTextElement) {
                setFontStyle({
                    bold: !!selectedTextElement.bold,
                    italic: !!selectedTextElement.italic,
                    underline: !!selectedTextElement.underline,
                    lineThrough: !!selectedTextElement.lineThrough,
                    color: selectedTextElement.color || 'black',
                });
            }
        }
        setIsTextStylingModalOpen(!isTextStylingModalOpen);
        if (!isTextStylingModalOpen) {
            setIsTextModalOpen(false);
            setIsAddTextSelected(false);
            setisInfoModalOpen(false);
        }
    };

    const openAddTextModal = () => {
        if (isInfoModalOpen) {
            setisInfoModalOpen(false);
        }
        setIsTextModalOpen(!isTextModalOpen);
        setIsAddTextSelected(!isTextModalOpen);
        setIsTextStylingModalOpen(false);
    };

    const addTextToCanvas = () => {
        const textWidth = measureTextWidth(
            "nochillio",
            35,
            fontStyle.bold ? 'bold' : fontStyle.italic ? 'italic' : 'normal',
            `${fontStyle.underline ? 'underline' : ''} ${fontStyle.lineThrough ? 'line-through' : ''}`
        );

        const newTextElement = {
            text: "nochillio",
            x: 10,
            y: textOption === 'top' ? 15 : textOption === 'bottom' ? canvasSize.height - 50 : canvasSize.height / 2,
            fontSize: 35,
            id: Math.random().toString(36).substr(2, 9),
            draggable: true,
            color: fontStyle.color,
            bold: fontStyle.bold,
            italic: fontStyle.italic,
            underline: fontStyle.underline,
            lineThrough: fontStyle.lineThrough,
        };

        setTextElements([...textElements, newTextElement]);
        setSelectedId(newTextElement.id);
        setIsTextModalOpen(false);
        setIsAddTextSelected(false);
        setFontStyle({
            bold: false,
            italic: false,
            underline: false,
            lineThrough: false,
            color: 'black',
        });
        setTextOption(null);
    };

	const measureTextWidth = (text, fontSize, fontWeight, fontStyle) => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		context.font = `${fontWeight} ${fontStyle} ${fontSize}px Anton`;
		return context.measureText(text).width;
	};

    const resetCanvas = () => {
        setElements([]);
        setTextElements([]);
        setSelectedId(null);
        setBackgroundColor("black");
        if (!isMobile) {
            adjustCanvasSize(600, 600);
        } else {
            adjustCanvasSize(mobileCanvasSize, mobileCanvasSize);
        }
    };

    const adjustCanvasSize = (imgWidth, imgHeight) => {
        let canvasWidth = imgWidth;
        let canvasHeight = imgHeight;

        const widthScale = maxCanvasWidth / imgWidth;
        const heightScale = maxCanvasHeight / imgHeight;
        const scale = Math.min(widthScale, heightScale, 1);

        canvasWidth = imgWidth * scale;
        canvasHeight = imgHeight * scale;

        stageRef.current.width(canvasWidth);
        stageRef.current.height(canvasHeight);
    };

    const handleTextEdit = (id) => {
        const textEl = textElements.find((te) => te.id === id);
        if (!textEl) return;

        const textPosition = stageRef.current.findOne(`#${id}`).getAbsolutePosition();
        const stageBox = stageRef.current.container().getBoundingClientRect();
        const areaPosition = {
            x: stageBox.left + textPosition.x,
            y: stageBox.top + textPosition.y,
        };

        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        textarea.value = textEl.text;
        textarea.style.position = "absolute";
        textarea.style.top = areaPosition.y + "px";
        textarea.style.left = areaPosition.x + "px";
        textarea.style.width = textEl.width + "px";
        textarea.focus();

        let removeTimeout;

        const removeTextarea = () => {
            const newText = textarea.value.trim();
            const updatedTextElements = textElements.map((te) => {
                if (te.id === id) {
                    return { ...te, text: newText };
                }
                return te;
            });

            setTextElements(updatedTextElements);
            if (textarea.parentNode) {
                document.body.removeChild(textarea);
            }
        };
        textarea.addEventListener("input", function () {
            const updatedTextElements = textElements.map((te) => {
                if (te.id === id) {
                    return { ...te, text: textarea.value };
                }

                return te;
            });

            setTextElements(updatedTextElements);
        });

        textarea.addEventListener("keydown", function (e) {
            clearTimeout(removeTimeout);
            if (e.key === "Enter") {
                removeTextarea();
                e.preventDefault();
            }
        });

        textarea.addEventListener("blur", function () {
            removeTimeout = setTimeout(removeTextarea, 0);
        });
    };

    const saveText = () => {
        const updatedTextElements = textElements.map((el) => {
            if (el.id === editingState.id) {
                return { ...el, text: editingState.value };
            }
            return el;
        });

        setTextElements(updatedTextElements);
        setEditingState({ visible: false, x: 0, y: 0, value: "", id: null });
    };

    const DraggableImage = React.memo(
        ({
            src,
            x,
            y,
            width,
            height,
            rotation,
            onDragEnd,
            id,
            onSelect,
            isSelected,
            onResize,
            flipX,
            flipY,
            draggable,
        }) => {
            const image = useImage(src);
            const offsetX = flipX ? width / 2 : 0;
            const offsetY = flipY ? height / 2 : 0;

            return image ? (
                <KonvaImage
                    image={image}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    offsetX={offsetX}
                    offsetY={offsetY}
                    rotation={rotation}
                    scaleX={flipX ? -1 : 1}
                    scaleY={flipY ? -1 : 1}
                    draggable={draggable}
                    onMouseDown={(e) => {
                        onSelect(id);
                        e.cancelBubble = true;
                    }}
                    onDragEnd={(e) => handleDragEnd(e, id)}
                    onTransformEnd={(e) => handleTransformEnd(e, id)}
                    onTap={(e) => {
                        onSelect(id);
                        e.cancelBubble = true;
                    }}
                    onTouchStart={(e) => {
                        e.evt.preventDefault();
                        onSelect(id);
                    }}
                    id={id}
                />
            ) : null;
        }
    );

    const handleResize = React.memo((id, newWidth, newHeight) => {
        setElements((prevElements) =>
            prevElements.map((element) => {
                if (element.id === id) {
                    return {
                        ...element,
                        width: newWidth,
                        height: newHeight,
                        scaleX: 1,
                        scaleY: 1,
                    };
                }
                return element;
            })
        );
    });

    const handleTransformEnd = useCallback((e, id) => {
        const node = e.target;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        const rotation = node.rotation();
        const x = node.x();
        const y = node.y();

        const newWidth = node.width() * scaleX;
        const newHeight = node.height() * scaleY;
        node.scaleX(1);
        node.scaleY(1);

        setElements((prevElements) =>
            prevElements.map((el) => {
                if (el.id === id) {
                    return {
                        ...el,
                        x,
                        y,
                        rotation,
                        width: Math.abs(newWidth),
                        height: Math.abs(newHeight),
                        scaleX,
                        scaleY,
                        flipX: scaleX < 0,
                        flipY: scaleY < 0,
                    };
                }
                return el;
            })
        );
    }, []);

    const handleSelect = useCallback((id) => {
        setSelectedId(id);
    }, []);
    useEffect(() => {}, [selectedId]);

    const [, drop] = useDrop(() => ({
        accept: "image",
        drop: (item, monitor) => {
            const clientOffset = monitor.getClientOffset();
            const stageBox = stageRef.current.container().getBoundingClientRect();
            const x = clientOffset.x - stageBox.left;
            const y = clientOffset.y - stageBox.top;
            addElementToCanvas(item.url, x, y);
        },
    }));

	const addElementToCanvas = (url, x, y, type, alt = '') => {
		const img = new Image();
		img.onload = () => {
			let maxWidth, maxHeight;
	
			switch (type) {
				case "asset":
					maxWidth = isMobile ? 120 : 200;
					maxHeight = isMobile ? 120 : 200;
					break;
				case "template":
				case "overlay":
				case "nochillio":
					maxWidth = isMobile ? mobileCanvasSize : 600;
					maxHeight = isMobile ? mobileCanvasSize : 600;
					break;
				default:
					maxWidth = 150;
					maxHeight = 150;
			}
	
			const aspectRatio = img.width / img.height;
			let newWidth = img.width;
			let newHeight = img.height;
	
			if (newWidth > maxWidth) {
				newWidth = maxWidth;
				newHeight = newWidth / aspectRatio;
			}
	
			if (newHeight > maxHeight) {
				newHeight = maxHeight;
				newWidth = newHeight * aspectRatio;
			}
	
			// Ensure alt is a string
			alt = alt || '';
	
			var newItem = {};
			if (alt.trim() === "Nochillio Image 1") {
				newItem = {
					src: url,
					x: x,
					y: y,
					width: newWidth,
					height: newHeight,
					id: Math.random().toString(36).substr(2, 9),
					type: type,
					rotation: 0,
					draggable: false,
					alt: alt.trim(),
				};
			} else {
				newItem = {
					src: url,
					x: x,
					y: y,
					width: newWidth,
					height: newHeight,
					id: Math.random().toString(36).substr(2, 9),
					type: type,
					rotation: 0,
					draggable: true,
					alt: alt.trim(),
				};
			}
	
			setElements((prevElements) => [...prevElements, newItem]);
			setSelectedId(newItem.id);
		};
		img.src = url;
	};
	
	

    const handleDragEnd = useCallback(
        (e, id) => {
            const index = elements.findIndex((el) => el.id === id);
            if (index === -1) return;

            const node = e.target;
            let newX = node.x();
            let newY = node.y();
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            const newRotation = node.rotation();
            const newWidth = node.width() * Math.abs(scaleX);
            const newHeight = node.height() * Math.abs(scaleY);
            const newFlipX = node.scaleX() < 0;
            const newFlipY = node.scaleY() < 0;

            const newElements = elements.map((el, i) => {
                if (i === index) {
                    return {
                        ...el,
                        x: newX,
                        y: newY,
                        width: newWidth,
                        height: newHeight,
                        rotation: newRotation,
                        flipX: newFlipX,
                        flipY: newFlipY,
                    };
                }
                return el;
            });

            setElements(newElements);
        },
        [elements]
    );

    const flipElementHorizontal = (id) => {
        setElements((prevElements) =>
            prevElements.map((el) => {
                if (el.id === id) {
                    return { ...el, flipX: !el.flipX };
                }
                return el;
            })
        );
    };

    const flipElementVertical = (id) => {
        setElements((prevElements) =>
            prevElements.map((el) => {
                if (el.id === id) {
                    return { ...el, flipY: !el.flipY };
                }
                return el;
            })
        );
    };

    const handleBackgroundUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setOriginalFileName(file.name);

            const reader = new FileReader();
            reader.onloadend = () => {
                const src = reader.result;
                addBackgroundToCanvas(src);
            };
            reader.readAsDataURL(file);

            event.target.value = "";
        }
    };

    const handleAssetUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setOriginalFileName(file.name);

            const reader = new FileReader();
            reader.onloadend = () => {
                const src = reader.result;
                addAssetToCanvas(src);
                event.target.value = "";
            };
            reader.readAsDataURL(file);
        }
    };

    const addBackgroundToCanvas = (src) => {
        const img = new Image();
        img.onload = () => {
            let maxImageWidth = isMobile ? mobileCanvasSize : 600;
            let maxImageHeight = isMobile ? mobileCanvasSize : 600;
            const aspectRatio = img.width / img.height;
            let imageWidth = img.width;
            let imageHeight = img.height;

            if (imageWidth > maxImageWidth) {
                imageWidth = maxImageWidth;
                imageHeight = imageWidth / aspectRatio;
            }
            if (imageHeight > maxImageHeight) {
                imageHeight = maxImageHeight;
                imageWidth = imageHeight * aspectRatio;
            }

            const newBackground = {
                src,
                x: 0,
                y: 0,
                width: imageWidth,
                height: imageHeight,
                id: "background",
                draggable: false,
            };

            const existingBackgroundIndex = elements.findIndex((el) => el.id === "background");
            if (existingBackgroundIndex > -1) {
                const updatedElements = [...elements];
                updatedElements[existingBackgroundIndex] = newBackground;
                setElements(updatedElements);
            } else {
                setElements([newBackground, ...elements]);
            }
        };
        img.src = src;
    };

    const addAssetToCanvas = (src) => {
        const img = new Image();
        img.onload = () => {
            const canvasWidth = stageRef.current.width();
            const canvasHeight = stageRef.current.height();

            const scaleX = canvasWidth / img.width;
            const scaleY = canvasHeight / img.height;
            const scale = Math.min(scaleX, scaleY, 1);

            const scaledWidth = img.width * scale;
            const scaledHeight = img.height * scale;

            setElements((prevElements) => [
                ...prevElements,
                {
                    src,
                    x: 0,
                    y: 0,
                    width: scaledWidth,
                    height: scaledHeight,
                    id: Math.random().toString(36).substr(2, 9),
                },
            ]);
        };
        img.src = src;
    };

    const increaseSize = () => {
        if (!selectedId) return;

        setElements((prevElements) =>
            prevElements.map((element) => {
                if (element.id === selectedId) {
                    if (element.src) {
                        const newWidth = element.width * 1.1;
                        const newHeight = element.height * 1.1;
                        return {
                            ...element,
                            width: newWidth,
                            height: newHeight,
                        };
                    }
                }
                return element;
            })
        );

        setTextElements((prevTextElements) =>
            prevTextElements.map((textElement) => {
                if (textElement.id === selectedId) {
                    if (textElement.fontSize) {
                        return {
                            ...textElement,
                            fontSize: textElement.fontSize + 2,
                        };
                    }
                }
                return textElement;
            })
        );
    };

    const decreaseSize = () => {
        if (!selectedId) return;

        setTextElements((prevTextElements) =>
            prevTextElements.map((textElement) => {
                if (textElement.id === selectedId) {
                    return {
                        ...textElement,
                        fontSize: Math.max(textElement.fontSize - 2, 1),
                    };
                }
                return textElement;
            })
        );

        setElements((prevElements) =>
            prevElements.map((element) => {
                if (element.id === selectedId) {
                    const newWidth = element.width * 0.9;
                    const newHeight = element.height * 0.9;
                    return { ...element, width: newWidth, height: newHeight };
                }
                return element;
            })
        );
    };

    const TransformerComponent = React.memo(({ selectedId }) => {
        const transformerRef = useRef();

        useEffect(() => {
            const timeout = setTimeout(() => {
                if (transformerRef.current && stageRef.current) {
                    const selectedNode = stageRef.current.findOne(`#${selectedId}`);
                    if (selectedNode) {
                        transformerRef.current.nodes([selectedNode]);
                        if (selectedNode.className === "Text") {
                            transformerRef.current.enabledAnchors([
                                "top-left",
                                "top-right",
                                "bottom-left",
                                "bottom-right",
                            ]);
                        } else {
                            transformerRef.current.enabledAnchors([
                                "top-left",
                                "top-right",
                                "bottom-left",
                                "bottom-right",
                            ]);
                        }
                    } else {
                        transformerRef.current.nodes([]);
                    }
                }
            }, 75);

            return () => clearTimeout(timeout);
        }, [selectedId]);
        if (!showTransformer) return null;
        return (
            <Transformer
                ref={transformerRef}
                keepRatio={false}
                borderStroke="#2d9bbd"
                borderStrokeWidth={3}
                anchorSize={8}
                anchorStroke="black"
                anchorFill="#2d9bbd"
                rotateEnabled={true}
            />
        );
    });

    const moveElementInArray = (array, fromIndex, toIndex) => {
        const newArray = [...array];
        const element = newArray.splice(fromIndex, 1)[0];
        newArray.splice(toIndex, 0, element);
        return newArray;
    };

    const moveElementUp = () => {
        if (selectedId) {
            const selectedIndex = elements.findIndex((element) => element.id === selectedId);
            if (selectedIndex > 0) {
                const updatedElements = moveElementInArray(elements, selectedIndex, selectedIndex - 1);
                setElements(updatedElements);
            }
        }
    };

    const moveElementDown = () => {
        if (selectedId) {
            const selectedIndex = elements.findIndex((element) => element.id === selectedId);
            if (selectedIndex < elements.length - 1) {
                const updatedElements = moveElementInArray(elements, selectedIndex, selectedIndex + 1);
                setElements(updatedElements);
            }
        }
    };

    const deleteSelectedImage = () => {
        let deleted = false;

        const isImageElement = elements.some((element) => element.id === selectedId);
        if (isImageElement) {
            const newElements = elements.filter((element) => element.id !== selectedId);
            setElements(newElements);
            deleted = true;
        } else {
            const newTextElements = textElements.filter((textElement) => textElement.id !== selectedId);
            setTextElements(newTextElements);
            deleted = newTextElements.length !== textElements.length;
        }

        setSelectedId(null);

        if (deleted && elements.length === 0 && textElements.length === 0) {
            adjustCanvasSize(600, 600);
        }
    };

    const toggleTextColor = (color) => {
        setTextElements(
            textElements.map((textElement) => {
                if (textElement.id === selectedId) {
                    return {
                        ...textElement,
                        color,
                    };
                }
                return textElement;
            })
        );
        setFontStyle((prevFontStyle) => ({
            ...prevFontStyle,
            color,
        }));
    };

    const toggleTextFormat = (format) => {
        setTextElements((prevTextElements) =>
            prevTextElements.map((textElement) => {
                if (textElement.id === selectedId) {
                    return {
                        ...textElement,
                        [format]: !textElement[format],
                    };
                }
                return textElement;
            })
        );
        setFontStyle((prevFontStyle) => ({
            ...prevFontStyle,
            [format]: !prevFontStyle[format],
        }));
    };

    const handleDownloadMergedImage = () => {
        setShowTransformer(false);

        const relevantElement = elements.find(
            (el) => el.id === "background" || el.type === "template" || "nochillio"
        );

        if (!relevantElement && !backgroundColor) {
            alert(
                "Please upload a background image, choose a background color, use a template, or use a nochillio before downloading."
            );
            console.error("Background image or color not found");
            setShowTransformer(true);
            return;
        }

        setTimeout(() => {
            const stage = stageRef.current.getStage();

            const minX = 0,
                minY = 0;
            const maxX = relevantElement ? Math.min(stage.width(), relevantElement.width) : stage.width();
            const maxY = relevantElement
                ? Math.min(stage.height(), relevantElement.height)
                : stage.height();

            const contentWidth = maxX - minX;
            const contentHeight = maxY - minY;

            const dataURL = stage.toDataURL({
                pixelRatio: 2,
                x: minX,
                y: minY,
                width: contentWidth,
                height: contentHeight,
            });

            const link = document.createElement("a");
            link.download = originalFileName ? `nochillio_${originalFileName}` : "nochillio.png";
            link.href = dataURL;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setTimeout(() => {
                stage.batchDraw();
                setShowTransformer(true);
            }, 100);
        }, 100);
    };

    return (
        <div className="filter-container">
            <div className="search-container-wrapper">
                <div className="search-container">
                    <button className="category-buttons" onClick={() => handleScenarioChange(2)}>
                        {scenario === 2 ? "✔️ meme my nochillio" : "meme my nochillio"}
                    </button>
                    <button className="category-buttons" onClick={() => handleScenarioChange(1)}>
                        {scenario === 1 ? "✔️ make a meme" : "make a meme"}
                    </button>

                    <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
                        {(isAssetModalOpen || isTemplateModalOpen || isNochillioModalOpen) && (
                            <>
                                <input
                                    className="search-text"
                                    type="text"
                                    placeholder={
                                        isNochillioModalOpen
                                            ? "Enter nochillio..."
                                            : `Enter ${isAssetModalOpen ? "assets" : "templates"}...`
                                    }
                                    value={
                                        isNochillioModalOpen
                                            ? nochillioSearchTerm
                                            : isAssetModalOpen
                                            ? searchTerms.asset
                                            : searchTerms.template
                                    }
                                    onChange={(e) =>
                                        isNochillioModalOpen
                                            ? setNochillioSearchTerm(e.target.value)
                                            : setSearchTerms({
                                                  ...searchTerms,
                                                  [isAssetModalOpen ? "asset" : "template"]: e.target.value,
                                              })
                                    }
                                    style={{ marginLeft: 0 }}
                                />
                                {isNochillioModalOpen && (
                                    <button
                                        className="search-button"
                                        onClick={() => fetchNochillioImages(nochillioSearchTerm)}
                                    >
                                        Enter
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="canvas-toolbar-container">
                {!isMobile && (
                    <div className="button-toolbar">
                        <div className="primary-buttons-container">
                            {scenario === 1 && (
                                <>
                                    <button
                                        className="primary-button"
                                        onClick={() => document.getElementById("fileInput1").click()}
                                        title="Upload meme template first"
                                    >
                                        upload meme template
                                    </button>
                                    <input
                                        id="fileInput1"
                                        type="file"
                                        onChange={handleBackgroundUpload}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
									<button className="primary-button" onClick={openTemplateModal}>
                                        {isTemplateModalOpen ? "✔️ templates" : "templates"}
                                    </button>
                                    <button className="primary-button" onClick={openAssetModal}>
                                        {isAssetModalOpen ? "✔️ select asset" : "select asset"}
                                    </button>
                                    <button
                                        className="primary-button"
                                        onClick={() => document.getElementById("fileInput2").click()}
                                        title="Must upload meme template before loading custom assets"
                                    >
                                        upload asset
                                    </button>
                                    <input
                                        id="fileInput2"
                                        type="file"
                                        onChange={handleAssetUpload}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <button className="primary-button" onClick={openAddTextModal}>
                                        {isAddTextSelected ? "✔️ add text" : "add text"}
                                    </button>
                                    <button className="primary-button" onClick={openTextStylingModal}>
                                        {isTextStylingModalOpen ? "✔️ text styling" : "text styling"}
                                    </button>
                                    {/* <button className="primary-button" onClick={handleOpenColorPicker}>
                                        background color
                                    </button> */}
                                    <input
                                        className="color-input"
                                        ref={colorInputRef}
                                        type="color"
                                        style={{ display: "none" }}
                                        onChange={handleColorChange}
                                    />
                                </>
                            )}
                            {scenario === 2 && (
                                <>
                                    <button className="primary-button" onClick={openNochillioModal}>
                                        {isNochillioModalOpen ? "✔️ select nochillio" : "select nochillio"}
                                    </button>
                                    <button
                                        className="primary-button"
                                        onClick={() => document.getElementById("fileInput1").click()}
                                        title="Upload background first"
                                    >
                                        upload background
                                    </button>
                                    <input
                                        id="fileInput1"
                                        type="file"
                                        onChange={handleBackgroundUpload}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <button className="primary-button" onClick={openOverlayModal}>
                                        {isOverlayModalOpen ? "✔️ select overlay" : "select overlay"}
                                    </button>
                                    <input
                                        id="fileInput2"
                                        type="file"
                                        onChange={handleAssetUpload}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <button className="primary-button" onClick={openAddTextModal}>
                                        {isAddTextSelected ? "✔️ add text" : "add text"}
                                    </button>
                                    <button className="primary-button" onClick={openTextStylingModal}>
                                        {isTextStylingModalOpen ? "✔️ text styling" : "text styling"}
                                    </button>
                                    {/* <button className="primary-button" onClick={handleOpenColorPicker}>
                                        background color
                                    </button> */}
                                    <input
                                        className="color-input"
                                        ref={colorInputRef}
                                        type="color"
                                        style={{ display: "none" }}
                                        onChange={handleColorChange}
                                    />
                                </>
                            )}
                        </div>
                        {(scenario === 1 || scenario === 2) && (
                            <div className="hide-button">
                                <button className="primary-button" onClick={toggleAdditionalButtons}>
                                    {showAdditionalButtons ? "↑ hide tools" : "↓ show tools"}
                                </button>
                            </div>
                        )}
                        {showAdditionalButtons && (
                            <div className="additional-buttons group-spacing">
                                {scenario === 1 && (
                                    <>
                                        <button className="primary-button" onClick={resetCanvas}>
                                            reset canvas
                                        </button>
                                        <button className="primary-button" onClick={moveElementDown}>
                                            layer up
                                        </button>
                                        <button className="primary-button" onClick={moveElementUp}>
                                            layer down
                                        </button>
                                        <button className="primary-button" onClick={increaseSize}>
                                            + size
                                        </button>
                                        <button className="primary-button" onClick={decreaseSize}>
                                            - size
                                        </button>
                                        <button
                                            className="primary-button"
                                            onClick={() => selectedId && flipElementHorizontal(selectedId)}
                                        >
                                            flip horizontal
                                        </button>
                                        <button
                                            className="primary-button"
                                            onClick={() => selectedId && flipElementVertical(selectedId)}
                                        >
                                            flip vertical
                                        </button>
                                        <button className="primary-button" onClick={deleteSelectedImage}>
                                            <img
                                                src="./images/trash.png"
                                                alt="Backward"
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                }}
                                            />
                                        </button>
                                    </>
                                )}
                                {scenario === 2 && (
                                    <>
                                        <button className="primary-button" onClick={resetCanvas}>
                                            reset canvas
                                        </button>
                                        <button className="primary-button" onClick={moveElementDown}>
                                            layer up
                                        </button>
                                        <button className="primary-button" onClick={moveElementUp}>
                                            layer down
                                        </button>
                                        <button className="primary-button" onClick={increaseSize}>
                                            + size
                                        </button>
                                        <button className="primary-button" onClick={decreaseSize}>
                                            - size
                                        </button>
                                        <button
                                            className="primary-button"
                                            onClick={() => selectedId && flipElementHorizontal(selectedId)}
                                        >
                                            flip horizontal
                                        </button>
                                        <button
                                            className="primary-button"
                                            onClick={() => selectedId && flipElementVertical(selectedId)}
                                        >
                                            flip vertical
                                        </button>
                                        <button className="primary-button" onClick={deleteSelectedImage}>
                                            <img
                                                src="./images/trash.png"
                                                alt="Backward"
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                }}
                                            />
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {/* Canvas */}
                <div ref={drop} className="canvas-frame">
                    <Stage
                        width={canvasSize.width}
                        height={canvasSize.height}
                        pixelRatio={window.devicePixelRatio}
                        ref={stageRef}
                        onMouseDown={(e) => {
                            if (e.target === e.target.getStage() || e.target.name() === "background") {
                                setSelectedId(null);
                            }
                        }}
                    >
                        <Layer>
                            <Rect
                                x={0}
                                y={0}
                                width={canvasSize.width}
                                height={canvasSize.height}
                                fill={backgroundColor}
                            />
                            {backgroundImage && (
                                <KonvaImage
                                    image={backgroundImage}
                                    name="background"
                                    width={canvasSize.width}
                                    height={canvasSize.height}
                                    draggable={false}
                                />
                            )}

                            {elements.map((element, i) => (
								<DraggableImage
  key={i}
  src={element.src}
  x={element.x}
  y={element.y}
  width={element.width}
  height={element.height}
  rotation={element.rotation || 0}
  onDragEnd={(e) => handleDragEnd(e, i)}
  isSelected={element.id === selectedId}
  onSelect={handleSelect}
  id={element.id}
  onResize={handleResize}
  draggable={
    element.id !== "background" && 
    element.type !== "template" && 
    element.alt !== "Nochillio Image 1" // Add condition for alt text
  }
  flipX={element.flipX || false}
  flipY={element.flipY || false}
  scaleX={element.flipX ? -1 : 1}
  scaleY={element.flipY ? -1 : 1}
/>

                            ))}

{ textElements.map((textElement, i) => (
    <Text
        key={i}
        {...textElement}
        width={canvasSize.width - 20} // Ensure the text box covers the canvas width
        fontFamily="Anton"
        fill={textElement.color}
        fontSize={textElement.fontSize}
        fontStyle={textElement.bold && textElement.italic ? 'bold italic' : textElement.bold ? 'bold' : textElement.italic ? 'italic' : 'normal'}
        textDecoration={[
            textElement.underline ? 'underline' : '',
            textElement.lineThrough ? 'line-through' : ''
        ].join(' ')}
        shadowColor={textElement.color === "black" ? "white" : "black"}
        shadowBlur={3}
        shadowOffset={{ x: 0, y: 0 }}
        shadowOpacity={1}
        align="center" // Align the text within its box
		lineHeight={1.1}
        onClick={() => handleSelect(textElement.id)}
        onDblClick={() => handleTextEdit(textElement.id)}
        onDragEnd={(e) => {
            const updatedTextElements = textElements.map((el) => {
                if (el.id === textElement.id) {
                    return {
                        ...el,
                        x: e.target.x(),
                        y: e.target.y(),
                    };
                }
                return el;
            });
            setTextElements(updatedTextElements);
        }}
        onTap={() => handleSelect(textElement.id)}
        onDblTap={() => handleTextEdit(textElement.id)}
    />
))}


                            {editingState.visible && (
                                <input
                                    style={{
                                        position: "absolute",
                                        top: `${editingState.y}px`,
                                        left: `${editingState.x}px`,
                                        zIndex: 100,
                                    }}
                                    autoFocus
                                    value={editingState.value}
                                    onChange={(e) =>
                                        setEditingState({
                                            ...editingState,
                                            value: e.target.value,
                                        })
                                    }
                                    onBlur={() => saveText()}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            saveText();
                                        }
                                    }}
                                />
                            )}

                            {selectedId && <TransformerComponent selectedId={selectedId} stageRef={stageRef} />}
                        </Layer>
                    </Stage>
                    <div className="bottom-and-modal">
                        {!isMobile && (
                            <div className="bottom-frame-buttons">
                                <button className="primary-button" onClick={handleDownloadMergedImage}>
                                    download
                                </button>
                            </div>
                        )}
                        {/* Mobile */}
                        {isMobile && scenario === 1 && (
                            <div className="bottom-frame-buttons">
                                <>
                                    <button className="primary-button" onClick={openTemplateModal}>
                                        {isTemplateModalOpen ? "✔️ templates" : "templates"}
                                    </button>
                                    <input
                                        id="fileInput2"
                                        type="file"
                                        onChange={handleAssetUpload}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <button
                                        className="primary-button"
                                        onClick={() => document.getElementById("fileInput2").click()}
                                        title="Must upload meme template before loading custom assets"
                                    >
                                        upload asset
                                    </button>
                                    <button className="primary-button" onClick={moveElementUp}>
                                        layer down
                                    </button>
									<button className="primary-button" onClick={openTextStylingModal}>
                                        {isTextStylingModalOpen ? "✔️ text styling" : "text styling"}
                                    </button>
                                    <button className="primary-button" onClick={decreaseSize}>
                                        - size
                                    </button>
                                    <button
                                        className="primary-button"
                                        onClick={() => selectedId && flipElementHorizontal(selectedId)}
                                    >
                                        flip horizontal
                                    </button>
									<button className="primary-button" onClick={resetCanvas}>
                                    	reset canvas
									</button>
									<button className="primary-button" onClick={handleDownloadMergedImage}>
										download
									</button>
                                </>
                            </div>
                        )}
                        {isMobile && scenario === 2 && (
                            <div className="bottom-frame-buttons">
                                <>
                                    <button
                                        className="primary-button"
                                        onClick={() => document.getElementById("fileInput1").click()}
                                        title="Upload background first"
                                    >
                                        upload background
                                    </button>
                                    <input
                                        id="fileInput1"
                                        type="file"
                                        onChange={handleBackgroundUpload}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <input
                                        id="fileInput2"
                                        type="file"
                                        onChange={handleAssetUpload}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <button
                                        className="primary-button"
                                        onClick={() => document.getElementById("fileInput2").click()}
                                        title="Must upload background before loading custom assets"
                                    >
                                        upload asset
                                    </button>
									<button className="primary-button" onClick={openTextStylingModal}>
                                    	{isTextStylingModalOpen ? "✔️ text styling" : "text styling"}
                                	</button>
                                    <button className="primary-button" onClick={moveElementUp}>
                                        layer down
                                    </button>
                                    <button className="primary-button" onClick={decreaseSize}>
                                        - size
                                    </button>
                                    <button
                                        className="primary-button"
                                        onClick={() => selectedId && flipElementHorizontal(selectedId)}
                                    >
                                        flip horizontal
                                    </button>
									<button className="primary-button" onClick={resetCanvas}>
                                    	reset canvas
									</button>
									<button className="primary-button" onClick={handleDownloadMergedImage}>
										download
									</button>
                                </>
                            </div>
                        )}
                        <div className="modal-mobile">
                            {isAssetModalOpen && (
                                <Modal isOpen={isAssetModalOpen} close={() => setIsAssetModalOpen(false)}>
                                    {renderAssetModalContent()}
                                </Modal>
                            )}
                            {isTemplateModalOpen && (
                                <Modal isOpen={isTemplateModalOpen} close={() => setIsTemplateModalOpen(false)}>
                                    {renderTemplateModalContent()}
                                </Modal>
                            )}
                            {isNochillioModalOpen && (
                                <Modal isOpen={isNochillioModalOpen} close={() => setIsNochillioModalOpen(false)}>
                                    {renderNochillioModalContent()}
                                </Modal>
                            )}
                            {isOverlayModalOpen && (
                                <Modal isOpen={isOverlayModalOpen} close={() => setIsOverlayModalOpen(false)}>
                                    {renderOverlayModalContent()}
                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
                {isMobile && scenario === 1 && (
                    <div className="button-toolbar">
                        <div className="primary-buttons">
                            <>
                                <button
                                    className="primary-button"
                                    onClick={() => document.getElementById("fileInput1").click()}
                                    title="Upload meme template first"
                                >
                                    upload meme template
                                </button>
                                <input
                                    id="fileInput1"
                                    type="file"
                                    onChange={handleBackgroundUpload}
                                    accept="image/*"
                                    style={{ display: "none" }}
                                />
                                <button className="primary-button" onClick={openAssetModal}>
                                    {isAssetModalOpen ? "✔️ select asset" : "select asset"}
                                </button>
                                <button className="primary-button" onClick={moveElementDown}>
                                    layer up
                                </button>
                                <button className="primary-button" onClick={openAddTextModal}>
                                    {isAddTextSelected ? "✔️ add text" : "add text"}
                                </button>
                                {/* <button className="primary-button" onClick={handleOpenColorPicker}>
                                    background color
                                </button> */}
                                {/* <input
                                    className="color-input"
                                    ref={colorInputRef}
                                    type="color"
                                    style={{ display: "none" }}
                                    onChange={handleColorChange}
                                /> */}
								<button className="primary-button" onClick={increaseSize}>
                                        + size
                                </button>
								<button
                                        className="primary-button"
                                        onClick={() => selectedId && flipElementVertical(selectedId)}
                                    >
                                        flip vertical
                                </button>
                                <button className="primary-button" onClick={deleteSelectedImage}>
                                    <img
                                        src="./images/trash.png"
                                        alt="Backward"
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                        }}
                                    />
                                </button>
                            </>
                            <div className="additional-buttons"></div>
                        </div>
                    </div>
                )}
                {isMobile && scenario === 2 && (
                    <div className="button-toolbar">
                        <div className="primary-buttons">
                            <>
                                <button className="primary-button" onClick={openNochillioModal}>
                                    {isNochillioModalOpen ? "✔️ select nochillio" : "select nochillio"}
                                </button>
                                <button className="primary-button" onClick={openOverlayModal}>
                                    {isOverlayModalOpen ? "✔️ select overlay" : "select overlay"}
                                </button>
                                <button className="primary-button" onClick={openAddTextModal}>
                                    {isAddTextSelected ? "✔️ add text" : "add text"}
                                </button>
                                {/* <button className="primary-button" onClick={handleOpenColorPicker}>
                                    background color
                                </button> */}
                                <input
                                    className="color-input"
                                    ref={colorInputRef}
                                    type="color"
                                    style={{ display: "none" }}
                                    onChange={handleColorChange}
                                />
								<button className="primary-button" onClick={moveElementDown}>
                                        layer up
                                </button>
								<button className="primary-button" onClick={increaseSize}>
                                        + size
                                </button>
								<button
                                    className="primary-button"
                                    onClick={() => selectedId && flipElementVertical(selectedId)}
                                >
                                    flip vertical
                                </button>
                                <button className="primary-button" onClick={deleteSelectedImage}>
                                    <img
                                        src="./images/trash.png"
                                        alt="Backward"
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                        }}
                                    />
                                </button>
                            </>
                            <div className="additional-buttons"></div>
                        </div>
                    </div>
                )}
            </div>
            <TextModal
                isOpen={isTextModalOpen}
                close={() => setIsTextModalOpen(false)}
                addTextToCanvas={addTextToCanvas}
                fontStyle={fontStyle}
                setFontStyle={setFontStyle}
                textOption={textOption}
                setTextOption={setTextOption}
            />
            <TextStylingModal
                isOpen={isTextStylingModalOpen}
                close={() => setIsTextStylingModalOpen(false)}
                toggleTextColor={toggleTextColor}
                toggleTextFormat={toggleTextFormat}
                fontStyle={fontStyle}
                selectedTextElement={textElements.find((textElement) => textElement.id === selectedId)}
            />

            {/* Guide Modal */}
            <Modal
                isOpen={isInfoModalOpen}
                close={() => setisInfoModalOpen(false)}
                className="guide-modal"
            >
                <h2 className="modal-h2-align">nochillio editor for autists</h2>
                <h3>meme my nochillio:</h3>
                <ol>
                    <li>look up your nochillio</li>
                    <li>customize it</li>
                </ol>
                <br />
                <br />
                <h3>make a meme:</h3>
                <ol>
                    <li>upload meme template/background</li>
                    <li>add your favorite assets</li>
                </ol>
                <button className="close-button" onClick={() => setisInfoModalOpen(false)}>
                    Close
                </button>
            </Modal>
            <img
                className="info-button"
                onClick={() => {
                    if (isTextModalOpen) {
                        setIsTextModalOpen(false);
                        setIsAddTextSelected(false);
                    }
                    if (isTextStylingModalOpen) {
                        setIsTextStylingModalOpen(false)
                    }
                    setisInfoModalOpen((prev) => !prev);
                }}
                src="../images/info-icon.png"
                style={{ width: "35px", height: "auto" }}
            />
        </div>
    );
};

export default NochillioEditor;
